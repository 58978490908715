<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">Consider the following reaction:</p>
      <p class="mb-3 pl-8">
        <chemical-latex content="C4H8 -> 2 C2H4" />
        <stemble-latex content="$\qquad\text{E}_\text{a}=262\text{ kJ/mol}$" />
      </p>

      <p class="mb-2">
        If the rate constant for this reaction at
        <stemble-latex content="$425\,\text{K}$" />
        is
        <latex-number :number="rateConstant1scaled.toFixed(5)" unit="\text{s}^{-1}," />
        what would the value of the rate constant be at
        <number-value :value="Temp2" unit="\text{K?}" />
      </p>

      <calculation-input
        v-model="inputs.rateConstant2"
        prepend-text="$\text{k:}$"
        append-text="$\text{s}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question98',
  components: {
    LatexNumber,
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateConstant2: null,
      },
    };
  },
  computed: {
    rateConstant1() {
      return this.taskState.getValueBySymbol('rateConstant1').content;
    },
    rateConstant1scaled() {
      return this.rateConstant1.toFloat() / 1000;
    },
    Temp2() {
      return this.taskState.getValueBySymbol('Temp2').content;
    },
  },
};
</script>
